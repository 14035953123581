<template>
	<div class="page page-agenda">
		<h2 class="is-title is-inverted is-simple">{{ title }}</h2>
		<div class="page-background is-short" :style="{ backgroundImage: backgroundUrl }"></div>
		<div class="container">
			<div class="agenda__detail">
				<div class="agenda__session__wrapper">
					<div class="agenda__session__main">
						<div v-if="isLogged" class="agenda__favourite-detail" :class="{ 'is-active': eventSession.favourite }">
							<a @click.prevent="toggleFavourite(eventSession)">
								<span class="icon">
									<inline-svg :src="require('../assets/calendar-plus.svg')" width="26"></inline-svg>
								</span>
								<span>Interested</span>
							</a>
						</div>

						<div class="agenda__session__time">
							<div>
								<p>
									<span>{{ eventSession.start | timeFormat(eventSession.date) }} - {{ eventSession.end | timeFormat(eventSession.date) }}</span> CET
								</p>
								<small
									><span>{{ eventSession.start | localTimeFormat(eventSession.date) }} - {{ eventSession.end | localTimeFormat(eventSession.date) }}</span> Your local time<br /><span
										>{{ eventSession.start | localTimeDateOnlyFormat(eventSession.date) }}</span
									></small
								>
							</div>
							<div v-if="eventSession.live_track && eventSession.live_track.title">
								<span class="agenda__session__track">{{ eventSession.live_track.title }}</span>
							</div>
							<div v-if="eventSession.session_hall && eventSession.session_hall.name">
								<span class="agenda__session__track">{{ eventSession.session_hall.name }}</span>
							</div>
						</div>
						<h2>
							{{ eventSession.title }}
						</h2>
						<h3 v-if="eventSession.session_type && eventSession.session_type.name">{{ eventSession.session_type.name }}</h3>
					</div>
					<div v-if="eventSession.session_logo" class="agenda__session__logo">
						<img :src="eventSession.session_logo | imageUrl" />
					</div>
					<div v-if="eventSession.starred" class="star"><inline-svg :src="require('../assets/star.svg')" width="25"></inline-svg></div>
				</div>
				<template v-if="eventSession.external !== true">
					<div v-if="!isLogged && hasIframeUrl" class="agenda__status is-not-logged">
						<span class="icon"><inline-svg :src="require('../assets/lock.svg')" width="25"></inline-svg></span>
						<span>Please <a :href="authUrl">login</a> or <a :href="registerUrl">register</a> to access the content</span>
					</div>

					<div v-if="isLogged && hasIframeUrl && isBefore(eventSession)" class="agenda__status is-before">
						<span class="icon"><inline-svg :src="require('../assets/timer.svg')" width="25"></inline-svg></span>
						<span>The live session has not started yet</span>
					</div>
					<router-link
						v-if="isLogged && hasTrack && isLive(eventSession)"
						:to="{ name: 'LiveTrack', params: { url: eventSession.live_track.url } }"
						class="agenda__status is-live"
						@click.native="eventClickJoin"
					>
						<span class="icon"><inline-svg :src="require('../assets/video-call.svg')" width="25"></inline-svg></span>
						<span>Click here to access the Live Session</span>
					</router-link>

					<div v-if="isLogged && hasIframeUrl && hasTrack && isProcessing(eventSession)" class="agenda__status is-processing">
						<span class="icon"><inline-svg :src="require('../assets/info.svg')" width="25"></inline-svg></span>
						<span>The live session has ended.<br />The presentations will become soon available in the on demand scientific content section.</span>
					</div>

					<div v-if="isLogged && hasIframeUrl && hasTrack && isProcessed(eventSession)" class="agenda__iframe">
						<iframe :src="iframeUrl" allow="fullscreen" />
					</div>

					<div v-if="isLogged && hasIframeUrl && !hasTrack && isLive(eventSession)" class="agenda__iframe">
						<iframe :src="iframeUrl" allow="fullscreen" />
					</div>

					<div v-if="isLogged && hasIframeUrl && !hasTrack && isAfter(eventSession)" class="agenda__iframe">
						<iframe :src="iframeUrl" allow="fullscreen" />
					</div>
				</template>
				<template v-if="isLogged && eventSession.external === true">
					<div v-if="eventSession.private === true && eventSession.user_allowed === false" class="agenda__status is-private">
						<span class="icon"><inline-svg :src="require('../assets/minus.svg')" width="25"></inline-svg></span>
						<span>You have not been registered for this session</span>
					</div>
					<div v-if="eventSession.user_allowed === true && isBeforeExternal(eventSession)" class="agenda__status is-before">
						<span class="icon"><inline-svg :src="require('../assets/timer.svg')" width="25"></inline-svg></span>
						<span>The {{ eventSession.external_provider_name ? eventSession.external_provider_name : 'Zoom Meeting' }} has not started yet</span>
					</div>
					<a v-if="eventSession.user_allowed === true && isLiveExternal(eventSession)" class="agenda__status is-live" :href="eventSession.external_url" target="_blank">
						<span class="icon"><inline-svg :src="require('../assets/video-call.svg')" width="25"></inline-svg></span>
						<span>Click here to access the {{ eventSession.external_provider_name ? eventSession.external_provider_name : 'Zoom Meeting' }}</span>
					</a>
				</template>

				<VueMarkdown v-if="eventSession.description" :source="eventSession.description" class="agenda__description content" :prerender="prerender" />
				
				<h4 v-if="eventSession.objectives">Objectives</h4>
				<div v-if="eventSession.objectives" v-html="eventSession.objectives"></div>


				<template v-if="eventSession.presentations && eventSession.presentations.length > 0">
					<h4>Presentations</h4>
					<div class="agenda__session__presentations">
						<div v-for="presentation in eventSession.presentations" :key="presentation.id" class="agenda__session__presentation">
							<AgendaSessionBox :session="presentation" view="presentation" :alt-title="false" :starred="true" />
						</div>
					</div>
				</template>

				<h4 v-if="eventSession.chairs && eventSession.chairs.length > 0">Chairs</h4>
				<div v-if="eventSession.chairs && eventSession.chairs.length > 0" class="agenda__session__speakers">
					<router-link v-for="speaker in eventSession.chairs" :key="speaker.id" :to="{ name: 'AgendaSpeaker', params: { url: speaker.url } }" class="agenda__session__speaker">
						<div class="image">
							<img v-if="speaker.profile_picture" :src="speaker.profile_picture | imageUrl('thumbnail')" />
							<img v-else src="@/assets/placeholder_speaker.jpg" />
						</div>
						<div>
							<h3>{{ speaker.firstName }} {{ speaker.lastName }}</h3>
							<h4>{{ speaker.country }}</h4>
						</div>
					</router-link>
				</div>

				<h4 v-if="eventSession.speakers && eventSession.speakers.length > 0">Speakers</h4>
				<div v-if="eventSession.speakers && eventSession.speakers.length > 0" class="agenda__session__speakers">
					<router-link v-for="speaker in eventSession.speakers" :key="speaker.id" :to="{ name: 'AgendaSpeaker', params: { url: speaker.url } }" class="agenda__session__speaker">
						<div class="image">
							<img v-if="speaker.profile_picture" :src="speaker.profile_picture | imageUrl('thumbnail')" />
							<img v-else src="@/assets/placeholder_speaker.jpg" />
						</div>
						<div>
							<h3>{{ speaker.firstName }} {{ speaker.lastName }}</h3>
							<h4>{{ speaker.country }}</h4>
						</div>
					</router-link>
				</div>

				<div class="agenda__detail__back">
					<router-link :to="{ name: 'Agenda' }">
						<span class="icon"><inline-svg :src="require('../assets/back.svg')" width="26"></inline-svg></span>
						<span>Back</span>
					</router-link>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
	import $http from '../utils/http';
	import VueMarkdown from 'vue-markdown-v2';
	import { prerender } from '../utils/markdown';
	import session from '../utils/session';
	import meta from '../utils/meta';
	import { AUTHURL, REGISTERURL } from '../consts';
	import moment from 'moment-timezone';
	import AgendaSessionModal from '../components/AgendaSessionModal';
	import AgendaSessionBox from '../components/AgendaSessionBox';

	export default {
		name: 'AgendaSession',
		components: { VueMarkdown, AgendaSessionBox },
		data() {
			return {
				eventSession: {},
				session: session.state,
				title: 'Event programme',
				subtitle: '',
				background: '',
				registerUrl: REGISTERURL,
				authUrl: AUTHURL,
			};
		},
		computed: {
			isLogged() {
				return this.session.loggedIn;
			},
			hasIframeUrl() {
				return !!this.eventSession.iframe_url;
			},
			hasTrack() {
				return !!this.eventSession.live_track;
			},
			backgroundUrl() {
				return this.background && this.background.url ? `url('${this.$options.filters.imageUrl(this.background)}')` : null;
			},
			iframeUrl() {
				let token = session.generateToken();
				if (!this.eventSession) return false;
				if (!this.eventSession.iframe_url) return false;
				return this.eventSession.iframe_url.replace('{token}', token);
			},
		},
		beforeRouteEnter(to, from, next) {
			$http.all([$http.get('page-event-programme'), $http.get(`/event-programme/${to.params.url}`)]).then(
				$http.spread((page, data) => {
					next(vm => {
						vm.setPage(page);
						vm.setData(data);
					});
				})
			);
		},
		beforeRouteUpdate(to, from, next) {
			$http.all([$http.get('page-event-programme'), $http.get(`/event-programme/${to.params.url}`)]).then(
				$http.spread((page, data) => {
					this.setPage(page);
					this.setData(data);
					next();
				})
			);
		},
		methods: {
			setPage({ data }) {
				if (data.title) {
					this.title = data.title;
				}
				meta.setTitle(this.title);
				this.subtitle = data.subtitle;
				this.background = data.background;
			},
			setData(data) {
				this.eventSession = data.data;
				if (this.eventSession.speakers) {
					this.eventSession.speakers = this.eventSession.speakers.sort((a, b) => (a.priority < b.priority ? 1 : a.priority > b.priority ? -1 : 0));
				}
				if (this.eventSession.presentations) {
					this.eventSession.presentations = this.eventSession.presentations.sort((a, b) => {
						return a.start.localeCompare(b.start);
					});
				}
			},
			toggleFavourite(eventSession) {
				eventSession.favourite = !eventSession.favourite;
				$http.put(`/event-programme-favourite/${eventSession.id}`).then(
					() => {},
					() => {
						eventSession.favourite = !eventSession.favourite;
					}
				);
			},
			prerender: prerender,
			isLive({ date, start, end }) {
				let target = moment.tz.guess();
				let dStart = moment.tz(`${date} ${start}`, 'CET');
				let dEnd = moment.tz(`${date} ${end}`, 'CET');
				let now = moment().tz(target);
				return now.isBetween(dStart, dEnd);
			},
			isBefore({ date, start }) {
				let target = moment.tz.guess();
				let dStart = moment.tz(`${date} ${start}`, 'CET');
				let now = moment().tz(target);

				return now.isBefore(dStart);
			},
			isLiveExternal({ date, start, end }) {
				let target = moment.tz.guess();
				let dStart = moment.tz(`${date} ${start}`, 'CET').subtract(15, 'minutes');
				let dEnd = moment.tz(`${date} ${end}`, 'CET');
				let now = moment().tz(target);

				return now.isBetween(dStart, dEnd);
			},
			isBeforeExternal({ date, start }) {
				let target = moment.tz.guess();
				let dStart = moment.tz(`${date} ${start}`, 'CET').subtract(15, 'minutes');
				let now = moment().tz(target);

				return now.isBefore(dStart);
			},
			isProcessing({ date, end }) {
				let target = moment.tz.guess();
				let dEnd = moment.tz(`${date} ${end}`, 'CET');
				let dEndP = moment.tz(`${date} ${end}`, 'CET').add(1, 'h');
				let now = moment().tz(target);

				return now.isBetween(dEnd, dEndP);
			},
			isProcessed({ date, end }) {
				let target = moment.tz.guess();
				let dEnd = moment.tz(`${date} ${end}`, 'CET').add(1, 'h');
				let now = moment().tz(target);

				return now.isAfter(dEnd);
			},
			isAfter({ date, end }) {
				let target = moment.tz.guess();
				let dEnd = moment.tz(`${date} ${end}`, 'CET');
				let now = moment().tz(target);

				return now.isAfter(dEnd);
			},
			eventClickJoin() {
				this.$gtm.dataLayer().push({
					event: 'gaEvent',
					eCategory: 'Programme Agenda Event',
					eAction: this.eventSession.session_category ? this.eventSession.session_category.title + ' - Join Session' : 'Join Session',
					eLabel: this.eventSession.title,
					Exhibitor: this.eventSession.session_ga ? this.eventSession.session_ga : '(not set)',
					Booth: '(not set)',
				});
			},
			openSession(session) {
				if (session.visitable) {
					this.$modal.show(
						AgendaSessionModal,
						{
							session,
						},
						{
							classes: 'agenda-session-modal',
							width: '90%',
							maxWidth: 800,
							height: 'auto',
							adaptive: true,
						}
					);
				}
			},
		},
	};
</script>
